<template>
  <div v-if="isLoading == false">
    <b-form>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Manufacturer:</label>
            <model-select
              v-model="form.make"
              :options="manufacturers"
              placeholder="select item"
            ></model-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Model:</label>
            <model-select
              :options="vehicleModels"
              v-model="form.model"
              placeholder="select item"
            ></model-select>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Plate Category:</label>
            <b-form-input
              id="input-1"
              v-model="form.plate_category"
              type="email"
              placeholder="Enter the plate category"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Plate Number:</label>
            <b-form-input
              id="input-1"
              v-model="form.plate_number"
              type="text"
              placeholder="Enter the plate number"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Manufacturing Year:</label>
            <b-form-input
              id="input-1"
              v-model="form.manufacturing_year"
              type="number"
              placeholder="Enter the manufacturing year"
              required
            ></b-form-input>
          </div>
        </div>
        <!-- <div class="col-6">
          <div class="form-group">
            <label for="input-1">Status:</label>
            <model-select
              :options="statusList"
              v-model="form.status"
              placeholder="select status"
            ></model-select>
          </div>
        </div> -->
      </div>

      <div class="container">
        <b-tabs content-class="mt-3">
          <b-tab @click="selectFront(true)" title="Vehicle Image" active>
            <div>
              <div style="display: flex; justify-content: center">
                <a :href="form.vehicle_image" target="_blank">
                  <img
                    :src="form.vehicle_image"
                    alt="License Front"
                    style="object-fit: contain; width: 600px; height: 100px"
                    class="img-fluid"
                  />
                </a>
              </div>

              <br />
              <fileUploader @file-uploaded="fileUploaded" />
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <b-button @click="updateVehicleInfo" variant="primary">
        <div v-if="isLoading">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <div v-else>Submit</div>
      </b-button>
    </b-form>
  </div>
  <div v-else class="d-flex justify-content-center">
    <b-spinner
      class="text-center"
      type="grow"
      variant="primary"
      animated
      small
    ></b-spinner>
  </div>
</template>

<script>
import fileUploader from "../../tools/file-uploader.vue";
import { driver } from "../../../config/api/drivers";
import { ModelSelect } from "vue-search-select";
export default {
  props: {
    selectedDriver: {
      type: Object,
      default: null,
    },
  },
  components: { fileUploader, ModelSelect },
  watch: {
    "form.make"(newVal) {
      this.setModels(newVal);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.getModelsandMakes();
      var propData = { ...this.selectedDriver.vehicles[0] };
      propData.vehicle_id = this.selectedDriver.vehicles[0]._id;
      propData.user_id = this.selectedDriver._id;

      propData.make = propData.make._id;
      this.setModels(propData.make);
      propData.model = propData.model._id;

      this.form = propData;
      this.isLoading = false;
    },
    setModels(selectedManufacturerId) {
      var tempList = [];

      this.allVehicleModels.forEach(function (item) {
        if (item.manufacturer_id == selectedManufacturerId) {
          tempList.push({ text: item.text, value: item.value });
        }
      });

      this.vehicleModels = tempList;
    },

    async getModelsandMakes() {
      const api = driver.getModelsandMakes;
      api.data = this.form;
      this.generateAPI(api).then((resdata) => {
        resdata.data.vehicles.forEach((e) => {
          if (e.manufacture) {
            this.allVehicleModels.push({
              text: e.name,
              value: e._id,
              manufacturer_id: e.manufacture._id,
            });
            this.vehicleModels.push({
              text: e.name,
              value: e._id,
              manufacturer_id: e.manufacture._id,
            });
          }
        });
        resdata.data.manufacturers.forEach((e) => {
          this.manufacturers.push({ text: e.name, value: e._id });
        });
      });
    },
    selectFront(val) {
      this.isFront = val;
    },
    fileUploaded(file) {
      this.form.vehicle_image = file;
    },
    updateVehicleInfo() {
      console.log("FORM");
      console.log(this.form);
      this.isLoading = true;
      const api = driver.updateVehicleDetails;
      api.data = this.form;
      this.generateAPI(api).then((resdata) => {
        console.log(resdata.data);
        this.isLoading = false;
        this.$emit("closeModal");
      });
    },
  },
  data() {
    return {
      isLoading: false,
      carManufacturer: "",
      isFront: true,
      vehicleModels: [],
      allVehicleModels: [],
      statusList: [
        {
          text: "Available",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },

        {
          text: "Engaged",
          value: 2,
        },
      ],
      manufacturers: [],

      form: {
        vehicle_id: "",
        manufacture: "",
        model: "",
        status: 1,
        plate_category: "",
        plate_number: "",
        manufacturing_year: 2022,
      },
    };
  },
};
</script>

<style></style>
