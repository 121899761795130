<template>
  <div v-if="isLoading == true" class="d-flex justify-content-center">
    <b-spinner class="text-center" type="grow" variant="primary" animated small></b-spinner>
  </div>
  <div v-else>
    <div class="col-12" v-if="
    selectedDriver.vehicles &&
    selectedDriver.vehicles.length &&
    selectedDriver.vehicles[0].upcoming_ad">
      <div class="form-group">

        <label for="input-1">Up-Coming AD</label>
        <div class="card-body">
          <img v-if="selectedDriver.vehicles[0].upcoming_ad.campaign_stickers" :src="selectedDriver.vehicles[0].upcoming_ad.campaign_stickers.sample_file" height="100px" />
        </div>
        <b-form-radio-group id="current_ad" v-model="upcomingSelected" name="radio-sub-component">
          <b-form-radio v-for="sticker, index in upcomingStickerOptions" v-bind:key="index" :value="sticker">
            <img :src="sticker.sample_file" alt="Sticker" height="80px" />
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <div class="col-12 mt-2">
        <b-button @click="updateStickerType(selectedDriver.vehicles[0].upcoming_ad._id, upcomingSelected)"
          variant="primary">
          <div v-if="isLoading">
            <b-spinner small type="grow"></b-spinner>
          </div>
          <div v-else>Submit</div>
        </b-button>
      </div>
    </div>


    <div class="col-12" v-if="
    selectedDriver.vehicles &&
    selectedDriver.vehicles.length &&
    selectedDriver.vehicles[0].current_ad">
      <div class="form-group">
        <label for="input-1">Current AD</label>
        <div class="card-body">

          <img v-if="selectedDriver.vehicles[0].current_ad.campaign_stickers" :src="selectedDriver.vehicles[0].current_ad.campaign_stickers.sample_file" height="100px" />
        </div>

        <b-form-radio-group id="current_ad" v-model="currentSelected" name="radio-sub-component">
          <b-form-radio v-for="sticker, index in currentStickerOptions" v-bind:key="index" :value="sticker">
            <img :src="sticker.sample_file" alt="Sticker" height="80px" />
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <div class="col-12 mt-2">
        <b-button @click="updateStickerType(selectedDriver.vehicles[0].current_ad._id, currentSelected)"
          variant="primary">
          <div v-if="isLoading">
            <b-spinner small type="grow"></b-spinner>
          </div>
          <div v-else>Submit</div>
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { campaigns } from "@/config/api/campaigns";

export default {
  data() {
    return {

      upcomingSelected: null,
      currentSelected: null,
      upcomingStickerOptions: [
      ],
      currentStickerOptions: [],
      isLoading: false,
    };
  },
  props: {


    selectedDriver: {
      type: Object,
    },

  },
  mounted() {
    if (this.selectedDriver.vehicles &&
      this.selectedDriver.vehicles.length &&
      this.selectedDriver.vehicles[0].upcoming_ad) {
      this.loadCampaignInfo(this.selectedDriver.vehicles[0].upcoming_ad.campaign._id).then((list) => {
        this.upcomingStickerOptions = list
      })
    }
    if (this.selectedDriver.vehicles &&
      this.selectedDriver.vehicles.length &&
      this.selectedDriver.vehicles[0].current_ad) {
      this.loadCampaignInfo(this.selectedDriver.vehicles[0].current_ad.campaign._id).then((list) => { this.currentStickerOptions = list })
    }
  },

  methods: {
    async loadCampaignInfo(campaignId) {
      const api = campaigns.getCampaignDetails;
      api.id = campaignId;
      let response = await this.generateAPI(api);
      return response.data.campaign.campaign_stickers;
    },
    updateStickerType(adId, selected) {
      this.isLoading = true;
      const api = campaigns.changeStickerV2;

      api.data = {
        campaign_stickers: selected,
        ad_id: adId
      };
      this.generateAPI(api)
        .then(() => {

          this.isLoading = false;
          this.$emit("closeModal");
          Swal.fire("Success", "Sticker changed", "success");
        })
        .catch((e) => {
          console.log(e.response);
          Swal.fire("Error", e.response.data.error, "warning");
          this.$emit("closeModal");
        });
    },
  },
};
</script>

<style>
</style>
