<template>
  <div v-if="isLoading == true" class="d-flex justify-content-center">
    <b-spinner class="text-center" type="grow" variant="primary" animated small></b-spinner>
  </div>
  <div v-else>
    <div class="col-12">
      <div class="form-group">
        <label for="input-1">Start Date:</label>
        <b-form-input id="input-1" v-model="form.start_date" type="date" placeholder="Enter the manufacturing year"
          required></b-form-input>
      </div>
    </div>
    <div class="row mb-2 mt-2">
      <div class="col-3">
        <b-button @click="today" variant="primary">
          <div>Today</div>
        </b-button>
      </div>
      <div class="col-3">
        <b-button @click="yesterday" variant="primary">
          <div>Yesterday</div>
        </b-button>
      </div>
    </div>
    <div class="col-12">
      <b-button @click="updateAdCampaignDate" variant="primary">
        <div v-if="isLoading">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <div v-else>Submit</div>
      </b-button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { campaigns } from "../../../config/api/campaigns";
import moment from 'moment';
export default {
  data() {
    return {
      form: {
        start_date: null,
      },
      isLoading: false,
    };
  },
  props: {
    userId: {
      type: String,
    },
    isFutureCampaign: {
      type: Boolean,
      default: false,
    },
    vehicleId: {
      type: String,
    },
  },
  mounted() { },

  methods: {
    today() {
      this.form.start_date = moment().format("YYYY-MM-DD")
    },
    yesterday() {
      this.form.start_date = (moment().subtract(1, 'day')).format("YYYY-MM-DD")
    },
    updateAdCampaignDate() {
      this.isLoading = true;
      const api = campaigns.changeAdDate;
      //TODO: add conditions for currentAd and upcomingAd

      api.data = {
        vehicle_id: this.vehicleId,
        start_date: this.form.start_date + "T00:00:00.000Z",
        futureAd: this.isFutureCampaign,
      };
      console.log(api.data);
      this.generateAPI(api)
        .then((resdata) => {
          console.log(resdata);

          this.isLoading = false;
          this.$emit("closeModal");
        })
        .catch((e) => {
          console.log(e.response);
          Swal.fire("Error", e.response.data.error, "warning");
          this.$emit("closeModal");
        });
    },
  },
};
</script>

<style>
</style>
