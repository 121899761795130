<template>
  <Layout>
    <div class="vehicle-ad">
      <div class="d-flex justify-content-between p-2 pt-4">
        <FormulateForm class="d-flex" @submit="submitHandler">
          <FormulateInput class="mr-2" placeholder="Search" name="term" />
          <FormulateInput type="submit" label="Search" />
        </FormulateForm>
        <div>

          <input type="checkbox" v-model="up_coming_ad" /> Upcoming Ad
          <input type="checkbox" v-model="current_ad" /> Current Ad

          <b-button variant="primary" style="height: 38px" size="sm" @click="refreshDriver">
            <span v-if="!loading">Refresh</span> <span v-else>Loading...</span>
          </b-button>
          <b-button class="ml-3" variant="primary" style="height: 38px" size="sm" @click="openAddDriversModal">
            <span>Add Drivers</span>
          </b-button>
        </div>
      </div>
      <b-table :items="items" :fields="fields" :per-page="perPage" :busy="loading" striped responsive="sm"
        style="min-height: 640px" show-empty>
        <template #empty>
          <div class="text-center">Drivers are not available!</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(driver_info)="row">
          <div>
            <div @click="showPersonalInfoEdit(row.item)" role="button">
              <strong>
                {{
                  row.item.license ? row.item.license.full_name : "N/A"
                }}</strong>
              <br />
              {{ row.item.email || "No Email ID" }}<br />

              +{{ row.item.country_code }}-{{ row.item.phone_number }}
              <br>
              <div v-if="row.item.vehicle_company_id">
                Company Account
              </div>
            </div>
            <div @click="showTerminalEditInfo(row.item)" role="button">
              Terminal Info: {{ row.item.tms_id && row.item.tms_id.serial_no }}
            </div>
          </div>
        </template>

        <template #cell(vehicles)="row">
          <div v-if="row.value.length" @click="row.toggleDetails" role="button">
            {{ row.value[0].make.name }} - {{ row.value[0].model.name }} (
            {{ row.value[0].manufacturing_year }}) <br />
            <strong>{{ row.value[0].plate_category }}
              {{ row.value[0].plate_number }}</strong><br />
          </div>
          <div v-else>No Vehicle</div>
          <div @click="showLocationEdit(row.item, 'locationModal')" role="button">
            Location - {{ row.value[0].city || place }}

          </div>
        </template>
        <template #cell(adInfo)="row">
          <div>
            <!-- @click="openModal(row.item, 'change_sticker_file')" -->
            <div v-if="
              row.item.vehicles &&
              row.item.vehicles.length &&
              row.item.vehicles[0].current_ad
            ">
              <strong> Current AD:</strong>
              {{ row.item.vehicles[0].current_ad.campaign.campaign_name || 'N/A' }}
              <p> Start date : {{ moment(row.item.vehicles[0].current_ad.start_date).format("lll") }}
                <b-button @click="openModal(row.item, 'changeDateModal')" variant="primary" size="sm" class="p-0 ml-2">
                  <i class="ri-pencil-line"></i></b-button>
              </p>
              <div class="div" @click="openModal(row.item, 'change_sticker_file')">
                <img v-if="row.item.vehicles[0].current_ad.campaign_stickers"
                  :src="row.item.vehicles[0].current_ad.campaign_stickers.sample_file" height="100px" />
              </div>
            </div>

            <div v-if="
            row.item.vehicles &&
            row.item.vehicles.length &&
            row.item.vehicles[0].upcoming_ad && row.item.vehicles[0].upcoming_ad.campaign_stickers">
              <strong>Upcoming AD:</strong>
              {{ row.item.vehicles[0].upcoming_ad.campaign.campaign_name || 'N/A' }}
              <p>Start date : Not started<b-button @click="openModal(row.item, 'changeDateModal', true)"
                  variant="primary" size="sm" class="p-0 ml-2">
                  <i class="ri-pencil-line"></i></b-button></p>
              <!-- <start-time-modal :data="{
                      id: row.item.vehicles[0].current_ad._id,
                      start_within:
                        row.item.vehicles[0].current_ad.start_within,
                    }" :isFutureCampaign=true /> -->

              <div class="div" @click="openModal(row.item, 'change_sticker_file')">
                <img v-if="row.item.vehicles[0].upcoming_ad.campaign_stickers"
                  :src="row.item.vehicles[0].upcoming_ad.campaign_stickers.sample_file" height="100px"
                  alt="sticker image" />
              </div>
            </div>
            <div v-else-if="
            row.item.vehicles &&
            row.item.vehicles.length &&
            row.item.vehicles[0].upcoming_ad" @click="openModal(row.item, 'change_sticker_file')">
              Error: No Sticker Found
            </div>
            <div class="mt-2" v-else>
              <b-button size="sm" @click="openModal(row.item, 'assignUpcomingAd')" variant="dark">Assign Upcoming Ad
              </b-button>
            </div>
          </div>
        </template>
        <template #cell(badge)="row">
          <ul style="list-style: none; margin: 0; padding: 0">
            <li v-if="
              row.item.vehicles &&
              row.item.vehicles.length &&
              row.item.vehicles[0].current_ad
            ">
              <b-badge variant="danger">
                Current ad
                {{
                  moment(row.item.vehicles[0].current_ad.start_date)
                                .add(row.item.vehicles[0].current_ad.no_of_days, "days")
                    .diff(moment(), "days") > 0
                    ? ` Expires in ${moment(
                      row.item.vehicles[0].current_ad.start_date
                    )
                      .add(row.item.vehicles[0].current_ad.no_of_days, "days")
                      .diff(moment(), "days")}`
                    : "Expired"
                }}
              </b-badge>
            </li>
            <li>
              <b-badge variant="danger" v-if="!row.item.license">
                No License
              </b-badge>
            </li>
            <li>
              <b-badge variant="danger" v-if="!row.item.bank_account">
                No Bank account
              </b-badge>
            </li>
            <li>
              <b-badge variant="success" v-if="
                row.item.vehicles &&
                row.item.vehicles.length &&
                row.item.vehicles[0].upcoming_ad
              
              ">
                Upcoming Ad
              </b-badge>
              <b-badge variant="danger" v-else> No Upcoming Ad </b-badge>
            </li>
            <li>
              <div v-if="
                row.item.vehicles &&
                row.item.vehicles.length &&
                row.item.vehicles[0].current_ad
              ">
                <b-badge variant="success"> Current Ad </b-badge>
              </div>
              <b-badge variant="danger" v-else> No Current Ad </b-badge>
            </li>
          </ul>
        </template>

        <template #row-details="row">
          <b-card>
            <div class="details-section">
              <div class="row">
                <div class="col-4">Balance</div>
                <div class="col-8">{{ row.item.amount_to_be_paid }} SAR</div>
              </div>
            </div>
            <!-- Personal Info -->
            <div class="details-section">
              <div class="row mb-1">
                <div class="col-4">
                  <h6>Personal Info</h6>
                </div>
                <div class="col-8">
                  <b-button size="sm" @click="showPersonalInfoEdit(row.item)" variant="dark">Edit
                  </b-button>
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-4">Mobile</div>
                <div class="col-8">
                  +{{ row.item.country_code }} - {{ row.item.phone_number }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Email</div>
                <div class="col-8">
                  {{ row.item.email }}
                </div>
              </div>
            </div>

            <div v-if="row.item.license" class="details-section">
              <div class="row mb-1">
                <div class="col-4">
                  <h6>License</h6>
                </div>
                <div class="col-8">
                  <b-button size="sm" @click="openModal(row.item, 'updateLicense')" variant="dark">Edit License
                  </b-button>
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-4">Name</div>
                <div class="col-8">
                  {{ row.item.license.full_name }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">License no.</div>
                <div class="col-8">
                  {{ row.item.license.license_number }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Nationality</div>
                <div class="col-8">
                  {{ row.item.license.nationality }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">License Pic</div>
                <div class="col-8">
                  <a class="bg-dark text-white px-2" :href="row.item.license.license_front" target="_blank"
                    rel="noopener noreferrer">
                    View
                  </a>

                  <span v-if="!row.item.license.license_front">
                    No image uploaded
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="pl-2">No License Information</div>
            <div v-if="row.item.vehicles && row.item.vehicles.length" class="details-section">
              <div class="row">
                <div class="col-4">
                  <h6>Vehicle</h6>
                </div>

                <div class="col-8">
                  <b-button size="sm" @click="openModal(row.item, 'updateVehicle')" variant="dark">Edit Vehicle
                  </b-button>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sticker type</div>
                <div class="col-8 cursor-pointer" @click="openModal(row.item, 'change_sticker_type')">
                  {{ row.item.vehicles[0].sticker_type }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Model</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].model.name }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Make</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].make.name }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Plate Category</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].plate_category }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Plate Number</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].plate_number }}
                </div>
              </div>
            </div>
            <div v-else class="pl-2">No Vehicle Available</div>

            <div v-if="
              row.item.vehicles &&
              row.item.vehicles.length &&
              row.item.vehicles[0].current_ad
            " class="details-section">
              <div class="d-flex justify-content-between">

                <h6>
                  <router-link :to='"campaigns/" + row.item.vehicles[0].current_ad.campaign._id'>Current Ad
                  </router-link>
                </h6>
              </div>
              <div class="row mb-1">
                <div class="col-4">Campaign Name</div>
                <div class="col-8">
                  {{
                    row.item.vehicles[0].current_ad.campaign.campaign_name ||
                      "N/A"
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Start date</div>
                <div class="col-8">
                  <span v-if="row.item.vehicles[0].current_ad.start_date">
                    {{
                      moment(row.item.vehicles[0].current_ad.start_date).format(
                        "LLL"
                      )
                    }}
                    <start-time-modal :data="{
                      id: row.item.vehicles[0].current_ad._id,
                      start_within:
                        row.item.vehicles[0].current_ad.start_within,
                    }" :isFutureCampaign=true />
                    <!-- <b-button
                      @click="changeDate(row)"
                      variant="primary"
                      size="sm"
                      class="p-0 ml-2"
                    >
                      <i class="ri-pencil-line"></i>
                    </b-button> -->
                  </span>

                  <span v-else> Not Started </span>
                  <b-button @click="openModal(row.item, 'changeDateModal', false)" variant="primary" size="sm"
                    class="p-0 ml-2">
                    <i class="ri-pencil-line"></i>
                  </b-button>
                </div>
              </div>
              <div class="row mb-1"
                v-if="row.item.vehicles[0].current_ad && row.item.vehicles[0].current_ad.start_date">
                <div class="col-4">Expire At</div>
                <div class="col-8">
                  {{
                    moment(row.item.vehicles[0].current_ad.start_date)
                                    .add(row.item.vehicles[0].current_ad.no_of_days, "days")
                      .format("LLL")
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">No. of days</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].current_ad.no_of_days }}
                  <change-days v-on:change="dateChanged" :data="{
                    ad_id: row.item.vehicles[0].current_ad._id,
                    is_current_ad: true,
                    id: row.item._id,
                    no_of_days: row.item.vehicles[0].current_ad.no_of_days,
                  }" />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sticker file</div>
                <div class="col-8">
                  <a class="bg-dark text-white px-2" :href="
                    row.item.vehicles[0].current_ad.campaign.sticker_file
                  " target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sample file</div>
                <div class="col-8" v-if="row.item.vehicles[0].current_ad">
                  <a :href="row.item.vehicles[0].current_ad.campaign_stickers.sample_file" target="_blank">
                    <img height="120px" :src="
                      row.item.vehicles[0].current_ad.campaign_stickers.sample_file
                    " alt="" srcset="" />
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="pl-2 row">
              <div class="col-4">No Current Ad</div>
            </div>

            <div v-if="
              row.item.vehicles &&
              row.item.vehicles.length &&
              row.item.vehicles[0].upcoming_ad
            " class="details-section">
              <div class="d-flex justify-content-between">
                <h6>
                  <router-link :to='"campaigns/" + row.item.vehicles[0].upcoming_ad.campaign._id'>Upcoming Ad
                  </router-link>
                </h6>

              </div>

              <div class="row mb-1">
                <div class="col-4">Campaign Name</div>
                <div class="col-8">
                  {{
                    row.item.vehicles[0].upcoming_ad.campaign.campaign_name ||
                      "N/A"
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Start date</div>
                <div class="col-8">
                  <span v-if="row.item.vehicles[0].upcoming_ad.start_date">
                    {{
                      moment(
                        row.item.vehicles[0].upcoming_ad.start_date
                                        ).format("LLL")
                    }}
                    <start-time-modal :data="{
                      id: row.item.vehicles[0].upcoming_ad._id,
                      start_within:
                        row.item.vehicles[0].upcoming_ad.start_date,
                    }" />
                  </span>
                  <span v-else> Not Started </span>
                  <b-button @click="openModal(row.item, 'changeDateModal', true)" variant="primary" size="sm"
                    class="p-0 ml-2">
                    <i class="ri-pencil-line"></i>
                  </b-button>
                </div>
              </div>
              <div class="row mb-1" v-if="row.item.vehicles[0].upcoming_ad.start_date">
                <div class="col-4">Expire At</div>
                <div class="col-8">
                  {{
                    moment(row.item.vehicles[0].upcoming_ad.start_date)
                                    .add(row.item.vehicles[0].upcoming_ad.no_of_days, "days")
                      .format("LLL")
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">No. of days</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].upcoming_ad.no_of_days }}
                  <change-days v-on:change="dateChanged" :data="{
                    id: row.item._id,
                    is_current_ad: false,
                    ad_id: row.item.vehicles[0].upcoming_ad._id,
                    no_of_days: row.item.vehicles[0].upcoming_ad.no_of_days,
                  }" />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sticker file</div>
                <div class="col-8">
                  <a class="bg-dark text-white px-2" :href="
                    row.item.vehicles[0].upcoming_ad.campaign.sticker_file
                  " target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </div>
              </div>
              <div class="row mb-1"
                v-if="row.item.vehicles[0].upcoming_ad && row.item.vehicles[0].upcoming_ad.sample_file">
                <div class="col-4">Sample file</div>
                <div class="col-8">
                  <a :href="
                    row.item.vehicles[0].upcoming_ad.campaign_stickers.sample_file
                  " target="_blank">
                    <img height="120px" :src="
                      row.item.vehicles[0].upcoming_ad.campaign_stickers.sample_file
                    " alt="" srcset="" />
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="pl-2 mt-2 row">
              <div class="col-4">No Upcoming Ad</div>
              <div class="col-8">
                <b-button size="sm" @click="openModal(row.item, 'assignUpcomingAd')" variant="dark">Assign Upcoming Ad
                </b-button>
              </div>
            </div>

            <div class="d-flex justify-content-end my-2">
              <b-button variant="primary" size="sm" @click="row.toggleDetails">Show Less</b-button>
            </div>
          </b-card>
        </template>
        <template #cell(action)="row">
          <b-dropdown variant="primary" text="Actions" class="m-md-2" size="sm" right>
            <b-dropdown-item @click="openUpdateModal(row)">Update Company</b-dropdown-item>
            <!-- <b-dropdown-item @click="openUpdateInfoModal(row)"
              >Update Driver</b-dropdown-item
            > -->
            <b-dropdown-item @click="viewMoreInfo(row)">More Info</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-between p-2 px-4">
        <div>
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select v-model="perPage" @change="perPageChanged" size="sm" :options="pageOptions"></b-form-select>
            &nbsp;{{ rows }}&nbsp;entries
          </label>
        </div>
        <b-pagination @change="pageChanged" :value="currentPage" :per-page="perPage" pills :total-rows="rows">
        </b-pagination>
      </div>
      <b-modal id="update-driver-modal" title="Update Driver Info" hide-footer>
      </b-modal>
      <b-modal id="updateDriversModal" ref="updateDriversModal" title="Update Company" hide-footer>
        <model-select v-model="selectedCompany" :options="options" placeholder="select item"></model-select>
        <div class="mt-2 text-center">
          <b-button @click="updateCompany">Update</b-button>
        </div>
      </b-modal>
      <b-modal id="addDriversModal" ref="addDriversModal" title="Create Drivers" hide-footer>
        <b-form-group id="input-group-1" label="Number of drivers:" label-for="input-1"
          description="The number of driver accounts you want to create">
          <b-form-input type="number" v-model="numberOfDrivers" placeholder="Please enter the number of drivers">
          </b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label="Company:" label-for="input-2">
          <model-select class="mt-2" v-model="selectedCompany" :options="options" placeholder="select item">
          </model-select>
        </b-form-group>

        <div class="mt-2 text-center">
          <b-button @click="createDrivers">Create</b-button>
        </div>
      </b-modal>
      <b-modal id="updateLicense" ref="updateLicense" title="Update License Info" hide-footer>
        <UpdateLicense @closeModal="closeModal" :selectedDriver="selectedDriver" />
      </b-modal>
      <b-modal id="updateVehicle" ref="updateVehicle" title="Update Vehicle Info" hide-footer>
        <UpdateVehicle @closeModal="closeModal" :selectedDriver="selectedDriver" />
      </b-modal>
      <b-modal id="assignUpcomingAd" ref="assignUpcomingAd" title="Assign Upcoming Ad" hide-footer>
        <AssignAd @closeModal="closeModal" :vehicleId="vehicleId" :userId="selectedDriverId" />
      </b-modal>
      <b-modal id="changeDateModal" ref="changeDateModal" title="Change Date" hide-footer>
        <ChangeDate @closeModal="closeModal" :vehicleId="vehicleId" :userId="selectedDriverId"
          :isFutureCampaign="isFutureCampaign" />
      </b-modal>
      <b-modal id="change_sticker_type" ref="change_sticker_type" title="Change Sticker Type" hide-footer>
        <ChangeSticker @closeModal="closeModal" :vehicleId="vehicleId" :userId="selectedDriverId"
          :sticker_type="sticker_type" />
      </b-modal>
      <b-modal id="change_sticker_file" ref="change_sticker_file" title="Change Sticker File" hide-footer>
        <ChangeStickerFile @closeModal="closeModal" :selectedDriver="selectedDriver" />
      </b-modal>
      <b-modal id="updateMobile" ref="updateMobile" title="Change Contact Information" hide-footer>
        <ChangeMobile @closeModal="closeModal" :email="selectedDriver.email" :phone_number="selectedDriver.phone_number"
          :country_code="selectedDriver.country_code" :user_id="selectedDriver._id" />
      </b-modal>
      <b-modal id="locationModal" ref="locationUpdate" title="Change Locations" hide-footer>
        <Changelocation @closeModal="closeModal" :vehicle_id="vehicleId" />
      </b-modal>
      <b-modal id="update_terminal_info" ref="update_terminal_info" title="Change Terminal Information" hide-footer>
        <ChangeTerminalInfo @closeModal="closeModal" :serial_no="selectedDriver.serial_number"
          :user_id="selectedDriver._id" />
      </b-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { driver } from "@/config/api/drivers";
import { vehicle_company } from "@/config/api/vehicle_company";
import ChangeDays from "@/components/vehicleAd/change-days";
import { ModelSelect } from "vue-search-select";
import UpdateLicense from "../../../components/driver/update_driver_modals/updatelicense.vue";
import UpdateVehicle from "../../../components/driver/update_driver_modals/updatevehicle.vue";
import AssignAd from "../../../components/driver/update_driver_modals/assignad.vue";
import ChangeDate from "../../../components/driver/update_driver_modals/changedate.vue";
import ChangeSticker from "../../../components/driver/update_driver_modals/changesticker.vue";
import ChangeStickerFile from "../../../components/driver/update_driver_modals/changestickerfile.vue";
import ChangeMobile from "../../../components/driver/update_driver_modals/changemobile.vue";
import Changelocation from "../../../components/driver/update_driver_modals/changelocation.vue";
import ChangeTerminalInfo from "../../../components/driver/update_driver_modals/changeteminalinfo.vue";
export default {
  components: {
    Layout,
    ModelSelect,
    ChangeDate,
    UpdateVehicle,
    ChangeDays,
    ChangeStickerFile,
    AssignAd,
    UpdateLicense,
    ChangeSticker,
    ChangeMobile,
    ChangeTerminalInfo,
    Changelocation,
  },
  created() {
    this.loadData();
    this.fetchCompanyIds();
  },
  data() {
    return {
      up_coming_ad: true,
      current_ad: true,
      vehicleId: "",
      fields: ["driver_info", "vehicles", "badge", "adInfo", "action"],
      selectedCompany: null,
      numberOfDrivers: 0,
      selectedDriverId: "",
      selectedUserId: null,
      options: [{ value: null, text: "No Company" }],
      items: [],
      loading: false,
      sticker_type: "Medium",
      selectedDriver: {},
      perPage: 10,
      isFutureCampaign: false,
      pageOptions: [10, 25, 50, 100],
      currentPage: 1,
      rows: 0,
      searchTerm: "",
      place: "Riyad"
    };
  },
  methods: {
    changeDate(val) {
      console.log(val);
      this.openModal(val.item, "changeDateModal");
      // if (val == "start") {

      // } else {
      // }
    },
    viewMoreInfo(row) {
      this.$router.push({
        path: `/drivers/profile/${row.item._id}`,
      });
    },
    openAddDriversModal() {
      this.$refs["addDriversModal"].show();
    },
    fetchCompanyIds() {
      const api = vehicle_company.getIds;

      this.loading = true;

      this.generateAPI(api).then((resdata) => {
        console.log(resdata);
        if (resdata.data.length > 0) {
          resdata.data.forEach((e) => {
            this.options.push({
              value: e._id,
              text: e.name,
            });
          });
        }
      });
    },
    showPersonalInfoEdit(data) {
      this.selectedDriver = data;
      console.log(data)
      this.$refs["updateMobile"].show();
    },
    showLocationEdit(data) {
      this.selectedDriver = data
      this.vehicleId = this.selectedDriver.vehicles[0]._id
      console.log(data);
      this.$refs["locationUpdate"].show()
    },
    showTerminalEditInfo(data) {
      this.selectedDriver = data;
      this.$refs["update_terminal_info"].show();
    },
    openModal(selectedDriver, type, isFuture) {
      // alert(isFuture)
      this.isFutureCampaign = !!isFuture;
      this.selectedDriver = selectedDriver;

      this.vehicleId = this.selectedDriver.vehicles[0]._id;
      if (this.selectedDriver.vehicles[0] && this.selectedDriver.vehicles[0].sticker_type) { this.sticker_type = this.selectedDriver.vehicles[0].sticker_type || "Large"; }
      this.selectedDriverId = selectedDriver._id;

      switch (type) {
        case "updateLicense":
          this.$refs["updateLicense"].show();
          break;
        case "updateVehicle":
          this.$refs["updateVehicle"].show();
          break;
        case "assignUpcomingAd":
          this.$refs["assignUpcomingAd"].show();
          break;
        case "changeDateModal":
          this.$refs["changeDateModal"].show();
          break;
        case "change_sticker_type":
          this.$refs["change_sticker_type"].show();
          break;
        case "change_sticker_file":
          this.$refs["change_sticker_file"].show();
          break;
        case "locationModal":
          this.$refs["locationUpdate"].show();
          break;
      }
    },
    createDrivers() {
      const api = vehicle_company.addDrivers;
      api.data = {
        numberOfDrivers: this.numberOfDrivers,
        companyId: this.selectedCompany,
      };

      this.loading = true;
      if (api.data.numberOfDrivers > 0 && api.data.companyId != null) {
        this.generateAPI(api).then((resdata) => {
          console.log(resdata);
          if (resdata.status == 200) {
            this.$bvToast.toast(resdata.data.message, {
              title: "Create",
              variant: "success",
              solid: true,
            });
          }
          this.closeModal();
        });
      } else {
        this.$bvToast.toast("Please fill all the fields", {
          title: "Create",
          variant: "danger",
          solid: true,
        });
      }
    },
    updateCompany() {
      const api = driver.updateCompany;
      api.data = {
        user_id: this.selectedUserId,
        company_id: this.selectedCompany,
      };

      this.loading = true;

      this.generateAPI(api).then((resdata) => {
        if (resdata.status == 200) {
          this.$bvToast.toast("Updated successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
        }
        this.closeModal();
      });
    },
    openUpdateModal(rowData) {
      console.log(rowData);
      if (rowData.item.company_id != null) {
        this.selectedCompany = rowData.item.company_id;
      }
      this.selectedUserId = rowData.item._id;
      this.$refs["updateDriversModal"].show();
    },
    openUpdateInfoModal(rowData) {
      this.selectedDriver = rowData.item;

      this.$refs["updateDriverInfo"].show();
    },

    closeModal() {
      this.$refs["updateDriversModal"].hide();
      this.$refs["addDriversModal"].hide();
      this.$refs["updateLicense"].hide();
      this.$refs["updateVehicle"].hide();
      this.$refs["assignUpcomingAd"].hide();
      this.$refs["change_sticker_type"].hide();
      this.$refs["changeDateModal"].hide();
      this.$refs["updateMobile"].hide();
      this.$refs["change_sticker_file"].hide();
      this.$refs["update_terminal_info"].hide();
      this.$refs["locationUpdate"].hide();

      this.loadData();
    },
    onRowClicked(item) {
      item._showDetails = true;
    },
    perPageChanged() {
      this.currentPage = 1;
      this.loadData();
    },
    pageChanged(val) {
      this.currentPage = val;
      this.loadData();
    },
    dateChanged(id, is_current_ad, updated_days) {
      console.log(id, updated_days);
      this.items.map((item) => {
        if (item._id === id) {
          if (is_current_ad) {
            item.vehicles[0].current_ad.no_of_days = updated_days;
          } else {
            item.vehicles[0].upcoming_ad.no_of_days = updated_days;
          }

          return item;
        } else {
          return item;
        }
      });
    },
    loadData() {
      const api = driver.vehicleAd;
      api.params = {
        limit: this.perPage,
        page: this.currentPage,
        search: this.searchTerm,
        no_upcoming_ad: this.up_coming_ad ? '' : 'true',
        no_current_ad: this.current_ad ? '' : 'true',
      };
      if (this.$route.query.companyId != null) {
        api.params.companyId = this.$route.query.companyId;
      }

      this.loading = true;

      this.generateAPI(api)
        .then((res) => {
          this.items = res.data.drivers;
          this.rows = res.data.total_no_of_documents;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submitHandler(data) {
      if (data.term.trim()) {
        this.searchTerm = data.term;
        await this.loadData();
      }
    },
    refreshDriver() {
      this.searchTerm = "";
      this.loadData();
    },
    campaignStickerChanged(data) {
      //  this.items.vehicles[0].upcoming_ad
      this.items = this.items.map((item) => {
        if ((item._id = data._id)) {
          item.vehicles[0].upcoming_ad.campaign = data;
        }
        return item;
      });
    },
    currentAdCampaignStickerChanged(data) {
      //  this.items.vehicles[0].upcoming_ad
      this.items = this.items.map((item) => {
        if ((item._id = data._id)) {
          item.vehicles[0].current_ad.campaign = data;
        }
        return item;
      });
    },
  },
  watch: {
    up_coming_ad() {
      this.currentPage = 1;
      this.loadData();
    },
    current_ad() {
      this.currentPage = 1;
      this.loadData();
    }
  }
};
</script>

<style>
.vehicle-ad {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 12px;
}

.vehicle-ad .details-section {
  background-color: #fafafa;
  padding: 8px 14px;
  margin: 4px;
  border-radius: 4px;
}
</style>
