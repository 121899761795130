export const vehicle_company = {
  get: {
    url: "vehicle_company/get_all",
    method: "GET",
    id: null,
    params: null,
  },
  getIds: {
    url: "vehicle_company/getIds",
    method: "GET",
    id: null,
    params: null,
  },
  add: {
    url: "vehicle_company/add",
    method: "POST",
    id: null,
    params: null,
  },
  update: {
    url: "vehicle_company/update",
    method: "POST",
    id: null,
    params: null,
  },
  delete: {
    url: "vehicle_company/delete",
    method: "POST",
    id: null,
    params: null,
  },
  addDrivers: {
    url: "vehicle_company/add_drivers",
    method: "POST",
    id: null,
    params: null,
  },
};
