<template>
  <div v-if="isLoading == true" class="d-flex justify-content-center">
    <b-spinner
      class="text-center"
      type="grow"
      variant="primary"
      animated
      small
    ></b-spinner>
  </div>
  <div v-else>
    <div class="col-12">
      <div class="form-group">
        <label for="input-1">Ad Campaign:</label>
        <model-select
          v-model="form.campaign_id"
          :options="campaigns"
          placeholder="select item"
        ></model-select>
      </div>
    </div>
    <div class="col-12">
      <b-button @click="updateAdCampaign" variant="primary">
        <div v-if="isLoading">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <div v-else>Submit</div>
      </b-button>
    </div>
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
import Swal from "sweetalert2";

import { campaigns } from "../../../config/api/campaigns";
export default {
  components: { ModelSelect },
  data() {
    return {
      form: {
        campaign_id: null,
      },
      isLoading: false,
      campaigns: [],
    };
  },
  props: {
    userId: {
      type: String,
    },
    vehicleId: {
      type: String,
    },
  },
  mounted() {
    this.getAvailableCampaigns();
  },

  methods: {
    getAvailableCampaigns() {
      this.isLoading = true;
      const api = campaigns.getCampaignForDriver;

      api.id = this.userId;

      this.generateAPI(api).then((res) => {
        console.log(res.data);
        res.data.campaigns.forEach((e) => {
          console.log(e);
          if (e.campaign_name) {
            var item = {
              value: e._id,
              text:
                e.campaign_name +
                "(Starts " +
                this.moment(e.start_within).format("ll") +
                " and lasts upto " +
                e.no_of_days +
                " days)",
            };
            this.campaigns.push(item);
          }
        });
        console.log(this.campaigns);
        this.isLoading = false;
      });
    },
    updateAdCampaign() {
      this.isLoading = true;
      const api = campaigns.assignDriverv2;
      api.data = {
        vehicleId: this.vehicleId,
        campaignId: this.form.campaign_id,
      };
      console.log(api.data);
      this.generateAPI(api)
        .then((resdata) => {
          console.log(resdata.data);
          this.$emit("closeModal");
        })
        .catch((e) => {
          console.log(e.response);
          Swal.fire("Error!", e.response.data.error, "warning");
          this.$emit("closeModal");
        });
    },
  },
};
</script>

<style></style>
