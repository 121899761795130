<template>
  <div>
    <b-form>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Name:</label>
            <b-form-input
              id="input-1"
              v-model="form.full_name"
              type="email"
              placeholder="Enter the full name"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">License Number:</label>
            <b-form-input
              id="input-1"
              v-model="form.license_number"
              type="email"
              placeholder="Enter the license number"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Nationality:</label>
            <b-form-input
              id="input-1"
              v-model="form.nationality"
              type="email"
              placeholder="Enter the nationality"
              required
            ></b-form-input>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Issue Date:</label>
            <b-form-input
              id="input-1"
              v-model="form.issue_date"
              type="date"
              placeholder="Select the Issue Date"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="input-1">Expiry Date:</label>
            <b-form-input
              id="input-1"
              v-model="form.expiry_date"
              type="date"
              placeholder="Enter the Expiry Date"
              required
            ></b-form-input>
          </div>
        </div>
      </div>

      <div class="container">
        <b-tabs content-class="mt-3">
          <b-tab @click="selectFront(true)" title="License Front" active>
            <div>
              <div style="display: flex; justify-content: center">
                <a :href="form.license_front" target="_blank">
                  <img
                    :src="form.license_front"
                    alt="License Front"
                    style="object-fit: contain; width: 600px; height: 100px"
                    class="img-fluid"
                  />
                </a>
              </div>

              <br />
              <fileUploader @file-uploaded="fileUploaded" />
            </div>
          </b-tab>
          <!-- <b-tab @click="selectFront(false)" title="License Back">
            <div>
              <div style="display: flex; justify-content: center">
                <a :href="form.license_back" target="_blank">
                  <img
                    :src="form.license_back"
                    alt="License Front"
                    style="object-fit: contain; width: 600px; height: 100px"
                    class="img-fluid"
                  />
                </a>
              </div>

              <br />
              <fileUploader @file-uploaded="fileUploaded" />
            </div>
          </b-tab> -->
        </b-tabs>
      </div>
      <b-button @click="updateLicenseInfo" variant="primary">
        <div v-if="isLoading">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <div v-else>Submit</div>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import fileUploader from "../../tools/file-uploader.vue";
import { driver } from "../../../config/api/drivers";

export default {
  props: {
    selectedDriver: {
      type: Object,
      default: null,
    },
  },
  components: { fileUploader },

  mounted() {
    console.log(this.selectedDriver);
    var propData = { ...this.selectedDriver.license };
    if (propData.issue_date) {
      propData.issue_date = new Date(propData.issue_date);
    }
    if (propData.expiry_date) {
      propData.expiry_date = propData.expiry_date.split("T")[0];
    }
    propData.user_id = this.selectedDriver._id;

    this.form = propData;
  },
  methods: {
    selectFront(val) {
      this.isFront = val;
    },
    fileUploaded(data) {
      if (this.isFront == true) {
        this.form.license_front = data;
      } else {
        this.form.license_back = data;
      }
    },
    updateLicenseInfo() {
      this.isLoading = true;
      const api = driver.updateLicenseInfo;
      api.data = this.form;
      this.generateAPI(api).then((resdata) => {
        console.log(resdata);
        this.isLoading = false;
        this.$emit("closeModal");
      });
    },
  },
  data() {
    return {
      isLoading: false,
      isFront: true,
      form: {
        user_id: "",
        full_name: "",
        expiry_date: "",
        issue_date: "",
        license_number: "",
        license_back: "",
        license_front: "",
        nationality: "",
      },
    };
  },
};
</script>

<style></style>
