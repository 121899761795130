<template>
  <div>
    <b-form>
      <div class="row">

        <div class="col-3">
          <FormulateInput name="country_code" type="number" label="Country code" v-model="form.country_code"
            placeholder="Country code" />
        </div>
        <div class="col-9">
          <FormulateInput v-model="form.phone_number" name="phone_number" type="number" label="Phone number"
            placeholder="Phone number" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <FormulateInput name="email" v-model="form.email" type="email" label="Email address"
            placeholder="Email address" validation="optional|email" />
        </div>


      </div>

      <b-button @click="updateInfo" variant="primary" class="mt-2">
        <div v-if="isLoading">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <div v-else>Submit</div>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { driver } from "../../../config/api/drivers";

export default {
  props: {
    country_code: {
      type: String
    },
    phone_number: {
      type: String
    },
    email: {
      type: String
    },
    user_id: {
      type: String
    },
  },

  mounted() {
    this.form = {
      email: this.email,
      phone_number: this.phone_number,
      country_code: this.country_code,
      user_id: this.user_id
    }
  },
  methods: {


    updateInfo() {
      this.isLoading = true;
      const api = driver.updatePersonalInfo;
      api.data = this.form;
      this.generateAPI(api).then((resData) => {
        console.log(resData);
        this.isLoading = false;
        this.$emit("closeModal");
      }).catch((e) => {
        Swal.fire("Error", e.response.data.message, "warning");
        this.$emit("closeModal");
      });
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        country_code: '',
        phone_number: "",
      },
    };
  },
};
</script>

<style>

</style>
